<template>
    <br>
    <br>
    <div class="card-parent">
        <h1>404</h1>
        <h3 class="citation">Not Found</h3>
        <h2>:(</h2>
    </div>
</template>

<style scoped>

</style>